.show-group {
  margin: 10px 0;

  label {
    display: block;
  }

  img {
    max-width: 100%;
  }

  #{&}--value {
    font-weight: bold;
  }
}